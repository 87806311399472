import React, { Component } from 'react';
import { withPrefix, Link } from 'gatsby';
import * as routes from '../../constants/routes';

class Opening extends Component {
  render() {
    return (
      <section className="section-hero">
        <div className="section-hero-bg">
          <video
            style={{ height: '100%', width: '100%' }}
            id="bgvid"
            loop={true}
            muted={true}
            autoPlay={true}
            playsInline={true}
            poster={withPrefix('main-image.jpg')}
            controls={true}
          >
            <source src={this.props.videoUrl} type="video/mp4" />
          </video>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-12 col-md-9">
              <h1>Sigma Software – Unicorn</h1>
              <p>Велокоманда, що постійно навчає нових спортсменів-аматорів</p>
            </div>
            <div className="col-12 d-flex flex-column flex-sm-row align-items-sm-center align-items-start justify-content-between ">
              <Link to={routes.TRAININGS} className="btn btn-primary">
                Реєстрація на тренування
              </Link>
              <div className="social-buttons">
                <a href="https://www.strava.com/clubs/448375?oq=AEROSTAR" target="_blank">
                  <svg className="icon icon-strava">
                    <use xlinkHref={withPrefix('assets/img/sprite/sprite.svg#ic-strava')} />
                  </svg>
                </a>
                <a href="https://www.facebook.com/profile.php?id=100008025610510" target="_blank">
                  <svg className="icon icon-fb">
                    <use xlinkHref={withPrefix('assets/img/sprite/sprite.svg#ic-facebook')} />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Opening;
