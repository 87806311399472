import React, { Component } from 'react';
import 'react-image-gallery/styles/css/image-gallery.css';
import ImageGallery from 'react-image-gallery';
import './image.css';

class Photo extends Component {
  render() {
    const images = [
      {
        original:
          'https://firebasestorage.googleapis.com/v0/b/volovod-488b2.appspot.com/o/IMG_0018.jpeg?alt=media&token=f02de4bd-fc48-4ac8-a8c1-738a3083b489',
        thumbnail:
          'https://firebasestorage.googleapis.com/v0/b/volovod-488b2.appspot.com/o/IMG_0018.jpeg?alt=media&token=f02de4bd-fc48-4ac8-a8c1-738a3083b489'
      },
      {
        original:
          'https://firebasestorage.googleapis.com/v0/b/volovod-488b2.appspot.com/o/IMG-5429.JPG?alt=media&token=e9142fe2-641a-4e6f-9273-cfee9ec0826e',
        thumbnail:
          'https://firebasestorage.googleapis.com/v0/b/volovod-488b2.appspot.com/o/IMG-5429.JPG?alt=media&token=e9142fe2-641a-4e6f-9273-cfee9ec0826e'
      },
      {
        original:
          'https://firebasestorage.googleapis.com/v0/b/volovod-488b2.appspot.com/o/sigma%20(3).jpg?alt=media&token=fed83ccf-1910-4a2d-ae17-cb9beeb8d8a6',
        thumbnail:
          'https://firebasestorage.googleapis.com/v0/b/volovod-488b2.appspot.com/o/sigma%20(3).jpg?alt=media&token=fed83ccf-1910-4a2d-ae17-cb9beeb8d8a6'
      },
      {
        original:
          'https://firebasestorage.googleapis.com/v0/b/volovod-488b2.appspot.com/o/sigma.jpg?alt=media&token=286424d0-69af-4323-ad58-19d86f1c3a29',
        thumbnail:
          'https://firebasestorage.googleapis.com/v0/b/volovod-488b2.appspot.com/o/sigma.jpg?alt=media&token=286424d0-69af-4323-ad58-19d86f1c3a29'
      },
      {
        original:
          'https://firebasestorage.googleapis.com/v0/b/volovod-488b2.appspot.com/o/sigma%20(85).jpg?alt=media&token=d95bf69f-0601-4cef-8dba-887248fcdc75',
        thumbnail:
          'https://firebasestorage.googleapis.com/v0/b/volovod-488b2.appspot.com/o/sigma%20(85).jpg?alt=media&token=d95bf69f-0601-4cef-8dba-887248fcdc75'
      }
    ];

    return <ImageGallery items={images} />;
  }
}

export default Photo;
