import React from 'react';
import Helmet from '../components/Helmet';
import { withFirebase } from '../components/Firebase/FirebaseContext';
import { withTrainings } from '../BuisenessLogic/TrainingsContext';
import Layout from '../components/layout';
import { graphql, Link, StaticQuery } from 'gatsby';
import Benefits from '../components/MainPage/Benefits';
import Opening from '../components/MainPage/Opening';
import Photo from '../components/MainPage/Photo';
import { LandingPage } from './trainings';

let iOSDevice;
if (typeof window !== 'undefined') {
  iOSDevice = !!window.navigator.platform.match(/iPhone|iPod|iPad/);
}

const videoUrl =
  Math.random() >= 0.5
    ? 'https://volovod.com/optimized.mp4'
    : 'https://firebasestorage.googleapis.com/v0/b/volovod-488b2.appspot.com/o/%D0%9C%D0%B8%D0%BD%D1%83%D1%82%D1%80%D0%BE%D0%B5%20%D0%B4%D0%BB%D1%8F%20%D0%94%D0%BE%D0%BB%D0%B6%D0%B8%D0%BA%D0%BE%D0%B2%D0%B0%20(3).mp4?alt=media&token=a707ee2a-04c0-4b49-b896-3a40ab4ee0b4';

const AboutPage = ({ data, ...props }) => {
  return (
    <main>
      <Helmet />
      <Opening videoUrl={videoUrl} />

      <div className="container">
        <p
          style={{
            width: '100%',
            textAlign: 'center',
            color: 'white',
            padding: '10px',
            marginTop: 10
          }}
        >
          <div>
            <a
              href={'https://cyclinghouse.com.ua'}
              style={{ cursor: 'pointer', paddingRight: 100 }}
            >
              <img
                src="https://static.tildacdn.com/tild3631-6632-4561-b464-663561666439/white_logo.png"
                alt=""
                style={{ height: 110 }}
              />
            </a>

            <a href={'https://sis.in.ua'} style={{ cursor: 'pointer' }}>
              <img
                src="https://www.scienceinsport.com/media/wysiwyg/m2_sis_logo_overlay_skinny.png"
                alt=""
                style={{ height: 50 }}
              />
            </a>
          </div>
        </p>
      </div>

      <Benefits />
      <Photo />
    </main>
  );
};

const DataWrapper = props => (
  <StaticQuery
    query={graphql`
      query Img {
        form: file(relativePath: { eq: "form.png" }) {
          childImageSharp {
            sizes(maxWidth: 1200) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        calendar: file(relativePath: { eq: "1234.png" }) {
          childImageSharp {
            sizes(maxWidth: 1200) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        f1: file(relativePath: { eq: "v1s.jpg" }) {
          childImageSharp {
            sizes(maxWidth: 400) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        f2: file(relativePath: { eq: "v2s.jpg" }) {
          childImageSharp {
            sizes(maxWidth: 400) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        f3: file(relativePath: { eq: "v3s.jpg" }) {
          childImageSharp {
            sizes(maxWidth: 400) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        f4: file(relativePath: { eq: "v4s.jpg" }) {
          childImageSharp {
            sizes(maxWidth: 400) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        f5: file(relativePath: { eq: "v5s.jpg" }) {
          childImageSharp {
            sizes(maxWidth: 400) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        f6: file(relativePath: { eq: "v6s.jpg" }) {
          childImageSharp {
            sizes(maxWidth: 400) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        f7: file(relativePath: { eq: "v7s.jpg" }) {
          childImageSharp {
            sizes(maxWidth: 400) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        f8: file(relativePath: { eq: "v7s.jpg" }) {
          childImageSharp {
            sizes(maxWidth: 400) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    `}
    render={data => {
      return <AboutPage {...props} data={data} />;
    }}
  />
);

const LandingPageWithFirebase = withFirebase(withTrainings(DataWrapper));

export default data => (
  <Layout>
    <LandingPageWithFirebase data={data} />
  </Layout>
);
